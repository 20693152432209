<template>
  <div class="sidebar_inner">
    <a-steps direction="vertical">
      <a-step
          v-for="(route, i) in visibleRoutes(loading,getSurvey().customer.has_partner.value,getSurvey().customer.invitation.type.value)"
          :key="route"
          :title="title(route)"
          :status="status(step, route, getSurvey().customer.has_partner.value, getSurvey().customer.invitation.type.value)"
          :disabled="isDisabled(i, step, route,getSurvey().customer.has_partner.value,getSurvey().customer.invitation.type.value)"
          @click="goTo(step, route, getSurvey().customer.has_partner.value, getSurvey().customer.invitation.type.value)"
      />
    </a-steps>
  </div>
</template>

<script>
import { getRoutes, getSideNavIndex, getTitle, router, showInSideNav } from '../../helpers'
import { mapState } from 'vuex'
import { isInIframe } from '@/helpers'

export default {
  name: 'side-nav',
  props: ['step', 'loading'],
  computed: {
    ...mapState('auth', ['access_token']),
    ...mapState('survey', ['customer'])
  },
  data: () => {
    return {
      isInIframe: isInIframe()
    }
  },
  methods: {
    goTo (step, route, hasPartner, invitationType) {
      if (getSideNavIndex(step, hasPartner, invitationType) > getSideNavIndex(route, hasPartner, invitationType)) {
        router.push(`/${route}`)
      }
    },
    visibleRoutes (loading, hasPartner, invitationType) {
      if (loading) {
        return []
      }
      return getRoutes(hasPartner, invitationType).filter(function (route) {
        return showInSideNav(route)
      })
    },
    title (route) {
      return getTitle(route)
    },
    status (step, route, hasPartner, invitationType) {
      return getSideNavIndex(step, hasPartner, invitationType) === getSideNavIndex(route, hasPartner, invitationType)
        ? 'process'
        : getSideNavIndex(step, hasPartner, invitationType) > getSideNavIndex(route, hasPartner, invitationType)
          ? 'finish'
          : 'wait'
    },
    getSurvey () {
      return this.$store.state.survey
    },
    getActiveIndex (route, hasPartner, invitationType) {
      return getSideNavIndex(route, hasPartner, invitationType)
    },
    getClass (step, route, hasPartner, invitationType) {
      return getSideNavIndex(step, hasPartner, invitationType) === getSideNavIndex(route, hasPartner, invitationType)
        ? 'active'
        : getSideNavIndex(step, hasPartner, invitationType) > getSideNavIndex(route, hasPartner, invitationType)
          ? 'done'
          : 'inactive'
    },
    isDisabled (step, route, hasPartner, invitationType) {
      return getSideNavIndex(step, hasPartner, invitationType) < getSideNavIndex(route, hasPartner, invitationType)
    },
    isActive (step, route, hasPartner, invitationType) {
      return getSideNavIndex(step, hasPartner, invitationType) === getSideNavIndex(route, hasPartner, invitationType)
    },
    isDone (step, route, hasPartner, invitationType) {
      return getSideNavIndex(step, hasPartner, invitationType) > getSideNavIndex(route, hasPartner, invitationType)
    },
    index (i, step, route, hasPartner, invitationType) {
      const stepIndex = getSideNavIndex(step, hasPartner, invitationType)
      const routeIndex = getSideNavIndex(route, hasPartner, invitationType)
      return stepIndex === routeIndex ? (i + 1) : stepIndex > routeIndex ? '✔' : (i + 1)
    }
  }
}
</script>
