<template>
  <div>
    <label :class="error ? '' : ''">{{ label }}
      <a-tooltip v-if="tooltip" placement="right">
        <template #title>
          <span>{{ tooltip }}</span>
        </template>
        <i class="mdi mdi-information"/>
      </a-tooltip>
    </label>
    <div class="input-group">
      <a-date-picker
          @change="onSubmit"
          :defaultValue="value"
          format="DD-MM-YYYY"
          placeholder="dd-mm-jjjj"
          :allowClear="clear"
      />
      <div v-if="error" class="invalid-feedback error-text">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import moment from 'moment'

export default {
  name: 'input-date',
  props: ['id', 'label', 'data', 'defaultValue', 'allowClear', 'error', 'tooltip'],
  data () {
    let value = null
    if (this.data) {
      if (this.data.includes('T')) {
        const formattedData = moment(this.data).format('DD-MM-YYYY')
        value = dayjs(formattedData, 'DD-MM-YYYY')
      } else {
        value = dayjs(this.data, 'DD-MM-YYYY')
      }
    } else {
      value = this.defaultValue ? dayjs(this.defaultValue, 'DD-MM-YYYY') : null
      // submit if value is not set, but defaultValue is
      this.submit({ name: this.id, value })
    }

    return {
      clear: this.allowClear !== false ? true : this.allowClear,
      value,
      errors: false
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onSubmit (date, dateString) {
      this.submit({ name: this.id, value: dateString })
      this.$emit('change', dateString)
    }
  }
}
</script>
