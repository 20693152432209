export const INTRO = 'intro'
export const START = 'start'
export const MANUAL = 'manual'
export const AQOPI = 'aqopi'
export const PERSONAL_DETAILS = 'personal_details'
export const CURRENT_LIVING_CONDITION = 'current_living_condition'
export const FINANCING = 'financing'
export const MORTGAGE = 'mortgage'
export const HOUSING_REQUIREMENTS = 'housing_requirements'
export const SUSTAINABILITY = 'sustainability'
export const MOVING = 'moving'
export const CHILDREN = 'children'
export const UNEMPLOYMENT = 'unemployment'
export const INCAPACITY_WORK = 'incapacity_work'
export const DEATH_OF_PARTNER = 'death_of_partner'
export const DIVORCE = 'divorce'
export const PENSION = 'pension'
export const MORTGAGE_AND_PENSION = 'mortgage_and_pension'
export const FUTURE_INCOME = 'future_income'
export const PERSONAL_CHARACTERISTICS = 'personal_characteristics'
export const QUESTIONS_FOR_THE_ADVISOR = 'questions_for_the_advisor'
export const COMPLETE = 'complete'
export const SUCCESS = 'success'
export const LOGOUT = 'logout'

export const fullWithPartner = [
  INTRO,
  PERSONAL_DETAILS,
  CURRENT_LIVING_CONDITION,
  FINANCING,
  MORTGAGE,
  SUSTAINABILITY,
  HOUSING_REQUIREMENTS,
  CHILDREN,
  UNEMPLOYMENT,
  INCAPACITY_WORK,
  DEATH_OF_PARTNER,
  DIVORCE,
  PENSION,
  MOVING,
  PERSONAL_CHARACTERISTICS,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const fullWithoutPartner = [
  INTRO,
  PERSONAL_DETAILS,
  CURRENT_LIVING_CONDITION,
  FINANCING,
  MORTGAGE,
  SUSTAINABILITY,
  HOUSING_REQUIREMENTS,
  CHILDREN,
  UNEMPLOYMENT,
  INCAPACITY_WORK,
  PENSION,
  MOVING,
  PERSONAL_CHARACTERISTICS,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const assessmentWithPartner = [
  INTRO,
  PERSONAL_DETAILS,
  CURRENT_LIVING_CONDITION,
  FINANCING,
  MORTGAGE,
  SUSTAINABILITY,
  PERSONAL_CHARACTERISTICS,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const assessmentWithoutPartner = [
  INTRO,
  PERSONAL_DETAILS,
  CURRENT_LIVING_CONDITION,
  FINANCING,
  MORTGAGE,
  SUSTAINABILITY,
  PERSONAL_CHARACTERISTICS,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const sourceData = [
  INTRO,
  PERSONAL_DETAILS,
  SUSTAINABILITY,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const financialAdvice = [
  INTRO,
  PERSONAL_DETAILS,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const customerProfile = [
  INTRO,
  PERSONAL_DETAILS,
  CURRENT_LIVING_CONDITION,
  FINANCING,
  MORTGAGE,
  SUSTAINABILITY,
  HOUSING_REQUIREMENTS,
  CHILDREN,
  UNEMPLOYMENT,
  INCAPACITY_WORK,
  DEATH_OF_PARTNER,
  DIVORCE,
  PENSION,
  MOVING,
  PERSONAL_CHARACTERISTICS,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const advice = [
  INTRO,
  PERSONAL_DETAILS,
  SUSTAINABILITY,
  HOUSING_REQUIREMENTS,
  CHILDREN,
  UNEMPLOYMENT,
  INCAPACITY_WORK,
  DEATH_OF_PARTNER,
  DIVORCE,
  PENSION,
  MOVING,
  PERSONAL_CHARACTERISTICS,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const partnerAdvice = [
  INTRO,
  PERSONAL_DETAILS,
  SUSTAINABILITY,
  HOUSING_REQUIREMENTS,
  CHILDREN,
  UNEMPLOYMENT,
  INCAPACITY_WORK,
  PENSION,
  MOVING,
  PERSONAL_CHARACTERISTICS,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const partner = [
  INTRO,
  PERSONAL_DETAILS,
  SUSTAINABILITY,
  HOUSING_REQUIREMENTS,
  CHILDREN,
  UNEMPLOYMENT,
  INCAPACITY_WORK,
  DEATH_OF_PARTNER,
  DIVORCE,
  PENSION,
  MOVING,
  PERSONAL_CHARACTERISTICS,
  QUESTIONS_FOR_THE_ADVISOR,
  COMPLETE,
  SUCCESS
]

export const mortgagePension = [
  INTRO,
  AQOPI,
  PERSONAL_DETAILS,
  MORTGAGE_AND_PENSION,
  FUTURE_INCOME,
  COMPLETE,
  SUCCESS
]

export const mortgagePensionPartner = [
  INTRO,
  AQOPI,
  PERSONAL_DETAILS,
  FUTURE_INCOME,
  COMPLETE,
  SUCCESS
]

export const mortgageEnrich = [
  INTRO,
  MORTGAGE_AND_PENSION,
  FUTURE_INCOME,
  COMPLETE,
  SUCCESS
]

export const debug = [
  INTRO,
  PERSONAL_DETAILS,
  COMPLETE,
  SUCCESS
]

export const FULL = 'full'
export const SOURCE_DATA = 'source_data'
export const FINANCIAL_ADVICE = 'financial_advice'
export const ASSESSMENT = 'assessment'
export const ADVICE = 'advice'
export const PARTNER = 'partner'
export const PARTNER_ADVICE = 'partner_advice'
export const PARTNER_ASSESSMENT = 'partner_assessment'
export const PARTNER_SOURCE_DATA = 'partner_source_data'
export const PARTNER_FINANCIAL_ADVICE = 'partner_financial_advice'
export const CUSTOMER_PROFILE = 'customer_profile'
export const MORTGAGE_PENSION = 'mortgage_pension'
export const MORTGAGE_ENRICH = 'mortgage_enrich'
export const DEBUG = 'debug'

export function getInvitationTypes () {
  return [
    { value: FULL, label: getInvitationName(FULL) },
    { value: SOURCE_DATA, label: getInvitationName(SOURCE_DATA) },
    { value: FINANCIAL_ADVICE, label: getInvitationName(FINANCIAL_ADVICE) },
    { value: ASSESSMENT, label: getInvitationName(ASSESSMENT) },
    { value: ADVICE, label: getInvitationName(ADVICE) },
    { value: PARTNER, label: getInvitationName(PARTNER) },
    { value: PARTNER_ADVICE, label: getInvitationName(PARTNER_ADVICE) },
    { value: PARTNER_ASSESSMENT, label: getInvitationName(PARTNER_ASSESSMENT) },
    { value: PARTNER_SOURCE_DATA, label: getInvitationName(PARTNER_SOURCE_DATA) },
    { value: PARTNER_FINANCIAL_ADVICE, label: getInvitationName(PARTNER_FINANCIAL_ADVICE) },
    { value: CUSTOMER_PROFILE, label: getInvitationName(CUSTOMER_PROFILE) },
    { value: PENSION, label: getInvitationName(PENSION) },
    { value: MORTGAGE, label: getInvitationName(MORTGAGE) },
    { value: MORTGAGE_PENSION, label: getInvitationName(MORTGAGE_PENSION) },
    { value: MORTGAGE_ENRICH, label: getInvitationName(MORTGAGE_ENRICH) },
    { value: DEBUG, label: getInvitationName(DEBUG) }
  ]
}

export function getInvitationName (invitationType) {
  switch (invitationType) {
    case FULL:
      return 'Volledig'
    case SOURCE_DATA:
      return 'Brondata'
    case FINANCIAL_ADVICE:
      return 'Brondata financieel advies'
    case ASSESSMENT:
      return 'Inventarisatie'
    case ADVICE:
      return 'Advies'
    case PARTNER:
      return 'Partner'
    case PARTNER_ADVICE:
      return 'Advies (partner)'
    case PARTNER_ASSESSMENT:
      return 'Inventarisatie (partner)'
    case PARTNER_SOURCE_DATA:
      return 'Brondata (partner)'
    case PARTNER_FINANCIAL_ADVICE:
      return 'Brondata financieel advies (partner)'
    case CUSTOMER_PROFILE:
      return 'Klantprofiel'
    case PENSION:
      return 'Hypotheekwacht (pensioen)'
    case MORTGAGE:
      return 'Hypotheekwacht (hypotheek)'
    case MORTGAGE_PENSION:
      return 'Hypotheekwacht'
    case MORTGAGE_ENRICH:
      return 'Hypotheek bewerken'
    case DEBUG:
      return 'Debug'
  }
  return invitationType
}

export function getQueryList (sources, invitationType) {
  // Fallback for invitations send before and used after the source refactor went live
  if (isHypotheekwacht(invitationType)) {
    return [
      { id: 'mbd-ext', data: ['via'] },
      {
        id: 'moh-ext',
        data: ['persoonsgegevens', 'familiegegevens', 'nationaliteitgegevens', 'id-gegevens', 'diplomagegevens', 'inkomensgegevens', 'voertuiggegevens', 'kadastergegevens', 'woz-gegevens']
      },
      { id: 'mpo-ext', data: ['pensioen-xml', 'pensioen-pdf'] }
    ]
  }
  return [
    { id: 'mbd-ext', data: ['via'] },
    { id: 'uwv-ext', data: ['verzekeringsbericht'] },
    {
      id: 'moh-ext',
      data: ['persoonsgegevens', 'familiegegevens', 'nationaliteitgegevens', 'id-gegevens', 'diplomagegevens', 'inkomensgegevens', 'voertuiggegevens', 'kadastergegevens', 'woz-gegevens']
    },
    { id: 'mpo-ext', data: ['pensioen-xml', 'pensioen-pdf'] }
  ]
}

export function isInvitationPartner (invitationType) {
  const invitationTypes = [PARTNER, PARTNER_ADVICE, PARTNER_ASSESSMENT, PARTNER_SOURCE_DATA, PARTNER_FINANCIAL_ADVICE]
  return invitationTypes.includes(invitationType)
}

export function getInvitationText (invitationType) {
  switch (invitationType) {
    case MORTGAGE:
    case MORTGAGE_PENSION:
    case MORTGAGE_ENRICH:
      return 'Voor een goed inzicht in uw hypotheek en inkomen is het belangrijk dat wij de ' +
        'juiste gegevens van uw huidige situatie ontvangen.'
    case PENSION:
      return 'Voor een goed inzicht in uw hypotheek en inkomen in De Hypotheekwacht is het belangrijk dat wij de ' +
        'juiste gegevens van uw huidige situatie ontvangen.'
    case FINANCIAL_ADVICE:
    case PARTNER_FINANCIAL_ADVICE:
      return 'Voor een goed en persoonlijk advies, is het belangrijk dat uw adviseur vooraf een goed beeld krijgt van ' +
        'uw huidige situatie.'
    default:
      return 'Voor een goed en persoonlijk advies, is het belangrijk dat uw adviseur vooraf een goed beeld krijgt van ' +
        'uw huidige situatie. De financiële gegevens bepalen vaak de mogelijkheden ' +
        'van uw hypotheek. Ook is het belangrijk om alvast na te denken over de verschillende ' +
        'situaties die kunnen ontstaan. Onderwerpen als werkloosheid, arbeidsongeschiktheid ' +
        'en overlijden zijn niet leuk om bij stil te staan, maar wel belangrijk om straks ' +
        'niet voor verrassingen te komen staan.'
  }
}

export function getDataProvideText (invitationType) {
  switch (invitationType) {
    case MORTGAGE:
    case MORTGAGE_PENSION:
    case PENSION:
      return 'U heeft ervoor gekozen om de gegevens digitaal aan te leveren. ' +
        'Er wordt een beperkt aantal gegevens opgehaald die nodig zijn voor De Hypotheekwacht. '
    case MORTGAGE_ENRICH:
      return 'Wij geven u de mogelijkheid om uw huidige informatie te actualiseren. ' +
        'De meest recente informatie vind u in het portaal van uw geldverstrekker.'
    default:
      return 'Wij geven u de mogelijkheid om de benodigde informatie alvast aan te leveren. ' +
        'Uw adviseur kan zich dan beter inleven in uw persoonlijke situatie ' +
        'en zich goed voorbereiden op het adviesgesprek. '
  }
}

export function getTitle (route) {
  switch (route) {
    case INTRO:
      return 'Aan de slag'
    case START:
      return 'Starten'
    case LOGOUT:
      return 'Uitloggen'
    case MANUAL:
      return 'Zelf invullen'
    case AQOPI:
      return 'Digitaal ophalen'
    case PERSONAL_DETAILS:
      return 'Persoonlijke gegevens'
    case CURRENT_LIVING_CONDITION:
      return 'Huidige woonsituatie'
    case FINANCING:
      return 'Financiering'
    case MORTGAGE:
      return 'Hypotheek'
    case SUSTAINABILITY:
      return 'Verduurzamen'
    case HOUSING_REQUIREMENTS:
      return 'Woonwensen voor de toekomst'
    case CHILDREN:
      return 'Kinderen'
    case UNEMPLOYMENT:
      return 'Werkloosheid'
    case INCAPACITY_WORK:
      return 'Arbeidsongeschiktheid'
    case DEATH_OF_PARTNER:
      return 'Overlijden van partner'
    case DIVORCE:
      return 'Scheiden / Uit elkaar'
    case PENSION:
      return 'Pensioen'
    case MOVING:
      return 'Verhuizen'
    case MORTGAGE_AND_PENSION:
      return 'Geldverstrekker'
    case FUTURE_INCOME:
      return 'Toekomstig inkomen'
    case PERSONAL_CHARACTERISTICS:
      return 'Persoonlijke kenmerken'
    case QUESTIONS_FOR_THE_ADVISOR:
      return 'Vragen voor de adviseur'
    case COMPLETE:
      return 'Gegevens versturen'
    case SUCCESS:
      return 'Gegevens verzonden'
  }
  return ''
}

export function getNotInState (hasPartner, invitationType) {
  return [INTRO, PERSONAL_DETAILS, COMPLETE, SUCCESS]
}

export function isInvitationExpired (invitation) {
  const validUntil = invitation.valid_until.value
  return validUntil ? Date.now() > new Date(validUntil).getTime() : false
}

export function isInvitationSubmitted (invitation) {
  return invitation.submitted_at ? invitation.submitted_at.value !== null : false
}

export function hasPartner (data) {
  return data.customer.has_partner.value === 1
}

export function isPartnerInvited (invitation) {
  return invitation.partner_invited ? invitation.partner_invited.value : false
}

export function getIntro (style, hasPartner, invitation) {
  if (isInvitationSubmitted(invitation)) {
    return [{
      color: '#594e4e',
      backgroundColor: '#e8e8e8',
      route: LOGOUT,
      title: 'Deze uitnodiging is al gebruikt',
      text_1: 'De inventarisatie is compleet en we hebben uw gegevens ontvangen.',
      text_2: isPartnerInvited(invitation) ? 'Uw partner heeft een separate uitnodiging via de e-mail ontvangen. Uw partner dient op de link in deze e-mail te klikken' : '',
      text_3: isPartnerInvited(invitation) ? 'Hierdoor start een sessie in de zogenaamde partneromgeving. Uw partner kan dus GEEN gebruik maken van uw inventarisatieportaal.' : '',
      buttonText: 'Uitloggen',
      buttonClass: 'primary mb-15'
    }]
  } else if (isInvitationExpired(invitation)) {
    return [{
      color: '#594e4e',
      backgroundColor: '#e8e8e8',
      route: LOGOUT,
      title: 'Uw uitnodiging is verlopen',
      text_1: 'Deze uitnodiging is niet meer geldig en u kunt geen gegevens naar ons opsturen.',
      text_2: isPartnerInvited(invitation) ? 'Uw partner heeft een separate uitnodiging via de e-mail ontvangen. Uw partner dient op de link in deze e-mail te klikken' : '',
      text_3: isPartnerInvited(invitation) ? 'Hierdoor start een sessie in de zogenaamde partneromgeving. Uw partner kan dus GEEN gebruik maken van uw inventarisatieportaal.' : '',
      buttonText: 'Uitloggen',
      buttonClass: 'primary mb-15'
    }]
  }

  const invitationType = invitation.type.value
  if (isHypotheekwacht(invitationType)) {
    return [{
      color: '#594e4e',
      backgroundColor: '#e8e8e8',
      route: AQOPI,
      title: 'Hypotheekwacht',
      text_1: '',
      text_2: '',
      text_3: '',
      buttonText: getTitle(INTRO),
      buttonClass: 'primary mb-15'
    }]
  }

  const routes = getRoutes(hasPartner, invitationType)
  const start = {
    color: '#594e4e',
    backgroundColor: '#e8e8e8',
    route: routes[1],
    title: getTitle(START),
    text_1: '',
    text_2: '',
    text_3: '',
    buttonText: getTitle(START),
    buttonClass: 'primary mb-15'
  }
  const manual = {
    color: '#594e4e',
    backgroundColor: '#e8e8e8',
    route: routes[1],
    title: getTitle(MANUAL),
    text_1: isOnlyManual(invitationType)
      ? ''
      : isEigenhuis()
        ? 'U kunt ervoor kiezen om op de knop "zelf invullen" te klikken. Indien u voor deze optie kiest kunnen er extra kosten in rekening worden gebracht.'
        : 'U kunt ervoor kiezen om op de knop "zelf invullen" te klikken.',
    text_2: 'Het duurt dan ongeveer 20 minuten om uw gegevens (en van uw eventuele partner) in te vullen.',
    text_3: '',
    buttonText: getTitle(MANUAL),
    buttonClass: 'primary mb-15'
  }
  const aqopi = {
    color: '#594e4e',
    backgroundColor: '#e8e8e8',
    route: AQOPI,
    title: getTitle(AQOPI),
    text_1: 'Het is eenvoudig, snel en voordelig om uw gegevens digitaal met uw adviseur te delen. Het inventarisatieproces duurt ongeveer 5 minuten. U kunt hiervoor klikken op de knop "digitaal ophalen". Via een beveiligde verbinding worden uw gegevens bij de volgende instanties opgehaald: belastingdienst, mijn overheid, het UWV en mijnpensioenoverzicht.',
    text_2: (isEigenhuis() && invitationType !== FINANCIAL_ADVICE && invitationType !== PARTNER_FINANCIAL_ADVICE)
      ? 'Door het verzamelen van deze gegevens krijgt uw adviseur een goed beeld van uw persoonlijke en financiële situatie.'
      : '',
    text_3: 'Om uw gegevens digitaal op te kunnen halen vragen wij u in de volgende stap een tijdelijke extensie te installeren. Aan het einde van de route wordt de extensie automatisch van uw computer verwijderd.',
    buttonText: getTitle(AQOPI),
    buttonClass: 'primary mb-15'
  }
  if (isOnlyStart(invitationType)) {
    return [start]
  } else if (isOnlyAqopi(invitationType)) {
    return [aqopi]
  } else if (isOnlyManual(invitationType)) {
    return [manual]
  }
  return [aqopi, manual]
}

export function showMortgages (invitationType) {
  const types = [MORTGAGE, MORTGAGE_PENSION, MORTGAGE_ENRICH]
  return types.includes(invitationType)
}

export function showPensions (invitationType) {
  const types = [PENSION, MORTGAGE_PENSION, MORTGAGE_ENRICH]
  return types.includes(invitationType)
}

export function isHypotheekwacht (invitationType) {
  const types = [MORTGAGE, MORTGAGE_PENSION, PENSION]
  return types.includes(invitationType)
}

export function isEigenhuis () {
  return (
    window.location.host.includes('mijnhypotheekvoorbereiden') ||
    window.location.host.includes('mijnadviesvoorbereiden.nl') ||
    window.location.host.includes('eigenhuis') ||
    window.location.host.includes('topicus')
  )
}

export function isOnlyStart (invitationType) {
  const types = [ADVICE, PARTNER_ADVICE, CUSTOMER_PROFILE]
  return types.includes(invitationType)
}

export function isOnlyAqopi (invitationType) {
  const types = [SOURCE_DATA, PARTNER_SOURCE_DATA, PENSION, MORTGAGE_PENSION, MORTGAGE, FINANCIAL_ADVICE, PARTNER_FINANCIAL_ADVICE]
  return types.includes(invitationType)
}

export function isOnlyManual (invitationType) {
  const types = [MORTGAGE_ENRICH]
  return types.includes(invitationType)
}

export function getHousingRequirements (hasPartner, invitationType) {
  const allRoutes = getRoutes(hasPartner, invitationType)
  if (!allRoutes.includes(HOUSING_REQUIREMENTS)) {
    return [] // no housing requirements in state
  }
  const routes = []
  const housingRequirements = [CHILDREN, UNEMPLOYMENT, INCAPACITY_WORK, DEATH_OF_PARTNER, DIVORCE, PENSION, MOVING]
  housingRequirements.forEach(route => {
    if (allRoutes.includes(route)) {
      routes.push(route)
    }
  })
  return routes
}

export function getSideNavIndex (route, hasPartner, invitationType) {
  const housingRequirements = getHousingRequirements(hasPartner, invitationType)
  if (route === INTRO) {
    return getIndex(INTRO, hasPartner, invitationType) - 1
  } else if (route === AQOPI) {
    return getIndex(PERSONAL_DETAILS, hasPartner, invitationType) - 1
  } else if (housingRequirements.indexOf(route) >= 0) {
    return getIndex(HOUSING_REQUIREMENTS, hasPartner, invitationType) - 1
  } else if (route === SUSTAINABILITY) {
    return getIndex(SUSTAINABILITY, hasPartner, invitationType) - 1
  } else if (route === PERSONAL_CHARACTERISTICS) {
    return getIndex(PERSONAL_CHARACTERISTICS, hasPartner, invitationType) - housingRequirements.length - 1
  } else if (route === QUESTIONS_FOR_THE_ADVISOR) {
    return getIndex(QUESTIONS_FOR_THE_ADVISOR, hasPartner, invitationType) - housingRequirements.length - 1
  } else if (route === COMPLETE) {
    return getIndex(COMPLETE, hasPartner, invitationType) - housingRequirements.length - 1
  } else if (route === SUCCESS) {
    return getIndex(COMPLETE, hasPartner, invitationType) - housingRequirements.length
  }
  return getIndex(route, hasPartner, invitationType) - 1
}

export function showInSideNav (route) {
  const routes = [INTRO, PERSONAL_DETAILS, CURRENT_LIVING_CONDITION, FINANCING, MORTGAGE, HOUSING_REQUIREMENTS, SUSTAINABILITY, MORTGAGE_AND_PENSION, FUTURE_INCOME, QUESTIONS_FOR_THE_ADVISOR, PERSONAL_CHARACTERISTICS, COMPLETE]
  return routes.includes(route)
}

export function hasRoute (route, hasPartner, invitationType) {
  return getRoutes(hasPartner, invitationType).includes(route)
}

export function getRoute (route, hasPartner, invitationType) {
  const routes = getRoutes(hasPartner, invitationType)
  return routes[routes.indexOf(route)]
}

export function getIndex (route, hasPartner, invitationType) {
  const routes = getRoutes(hasPartner, invitationType)
  return routes.indexOf(route)
}

export function isFirst (route, invitationType) {
  const routes = getRoutes(false, invitationType)
  return routes.indexOf(route) === 0
}

export function getRoutes (hasPartner, invitationType) {
  switch (invitationType) {
    case FULL:
      return hasPartner ? fullWithPartner : fullWithoutPartner
    case ADVICE:
      return advice
    case PARTNER_ADVICE:
      return partnerAdvice
    case SOURCE_DATA:
      return sourceData
    case FINANCIAL_ADVICE:
    case PARTNER_FINANCIAL_ADVICE:
      return financialAdvice
    case ASSESSMENT:
      return hasPartner ? assessmentWithPartner : assessmentWithoutPartner
    case PARTNER_ASSESSMENT:
      return assessmentWithoutPartner
    case PARTNER_SOURCE_DATA:
      return sourceData
    case PARTNER:
      return partner
    case CUSTOMER_PROFILE:
      return customerProfile
    case MORTGAGE:
      return mortgagePension
    case PENSION:
      return mortgagePension
    case MORTGAGE_PENSION:
      return mortgagePension
    case MORTGAGE_ENRICH:
      return mortgageEnrich
    case DEBUG:
      return debug
  }
  return sourceData
}

export function previousRoute (currentRoute, hasPartner, invitationType) {
  const routes = getRoutes(hasPartner, invitationType)
  return routes.indexOf(currentRoute) >= 0 ? routes[routes.indexOf(currentRoute) - 1] : 'error'
}

export function nextRoute (currentRoute, hasPartner, invitationType) {
  const routes = getRoutes(hasPartner, invitationType)
  return routes[routes.indexOf(currentRoute) + 1]
}
