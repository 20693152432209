<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>Persoonlijke gegevens</h1>
          <p v-if="isHypotheekwacht">
            Een aantal persoonlijke en financiële gegevens hebben wij alvast voor u ingevuld. Controleer deze
            gegevens goed, vul ze aan en/of wijzig deze daar dat nodig is.
          </p>
          <p v-else>
            Een aantal persoonlijke en financiële gegevens uit de brondata-sessie, hebben wij alvast voor u ingevuld.
            Controleer deze gegevens goed, vul ze aan of wijzig deze waar dat nodig is. Uw eventuele partner zal na het
            toevoegen van zijn of haar e-mailadres een separate uitnodiging ontvangen.
          </p>
          <div class="well">
            <h2 class="pt-20">Persoonlijke gegevens</h2>
            <a-row :gutter="20" class="pt-10">
              <a-col :sm="16" :md="6">
                <input-select
                    v-if="!isHypotheekwacht"
                    id="customer.salutation.value"
                    :label="data.customer.salutation.title"
                    :data="data.customer.salutation.value"
                    :options="data.customer.salutation.options"
                    :error="submitted ? data.customer.salutation.error : null"
                />
                <input-select
                    v-else
                    id="customer.salutation.value"
                    :label="data.customer.salutation.title"
                    :data="data.customer.salutation.value"
                    :options="getHWSalutations()"
                    :error="submitted ? data.customer.salutation.error : null"
                />
              </a-col>
              <a-col :sm="16" :md="18">
                <input-text
                    v-if="!isHypotheekwacht"
                    id="customer.first_name.value"
                    :label="data.customer.first_name.title"
                    :data="data.customer.first_name.value"
                    :error="submitted ? data.customer.first_name.error : null"
                />
                <input-text
                    v-else
                    id="customer.initials.value"
                    :label="data.customer.initials.title"
                    :data="data.customer.initials.value"
                    :error="submitted ? data.customer.initials.error : null"
                />
              </a-col>
            </a-row>
            <a-row :gutter="20" class="pt-10">
              <a-col :sm="16" :md="6">
                <input-text
                    id="customer.insertion.value"
                    :label="data.customer.insertion.title"
                    :data="data.customer.insertion.value"
                    :error="submitted ? data.customer.insertion.error : null"
                />
              </a-col>
              <a-col :sm="24" :md="18">
                <input-text
                    id="customer.last_name.value"
                    :label="data.customer.last_name.title"
                    :data="data.customer.last_name.value"
                    :error="submitted ? data.customer.last_name.error : null"
                />
              </a-col>
            </a-row>
            <a-row :gutter="20" class="pt-10">
              <a-col :md="8">
                <input-date
                    id="customer.date_of_birth.value"
                    :label="data.customer.date_of_birth.title"
                    :data="data.customer.date_of_birth.value"
                    :error="submitted ? data.customer.date_of_birth.error : null"
                />
              </a-col>
              <a-col :md="6">
                <input-select
                    id="customer.gender.value"
                    :label="data.customer.gender.title"
                    :data="data.customer.gender.value"
                    :options="data.customer.gender.options"
                    :error="submitted ? data.customer.gender.error : null"
                />
              </a-col>
              <a-col :md="10">
                <input-select
                    id="customer.maritial_status.value"
                    :label="data.customer.maritial_status.title"
                    :data="data.customer.maritial_status.value"
                    :options="data.customer.maritial_status.options"
                    :error="submitted ? data.customer.maritial_status.error : null"
                />
              </a-col>
            </a-row>
            <a-row :gutter="20" class="pt-10" v-if="!isHypotheekwacht">
              <a-col :sm="24" :md="14" :lg="8">
                <input-text
                    id="customer.customer_number.value"
                    :label="data.customer.customer_number.title"
                    :data="data.customer.customer_number.value"
                    tooltip="Lidnummer Verenging Eigen Huis"
                    :error="submitted ? data.customer.customer_number.error : null"
                />
              </a-col>
              <a-col :sm="24" :md="14" :lg="8">
                <input-select
                    id="customer.smoking.value"
                    :label="data.customer.smoking.title"
                    :data="data.customer.smoking.value"
                    :options="data.customer.smoking.options"
                    :error="submitted ? data.customer.smoking.error : null"
                />
              </a-col>
            </a-row>
            <a-row :gutter="10">
              <a-col
                  v-if="!isHypotheekwacht" :sm="24" :md="12" class="pt-10">
                <input-text
                    id="customer.phone.value"
                    :label="data.customer.phone.title"
                    :data="data.customer.phone.value"
                    :error="submitted ? data.customer.phone.error : null"
                />
              </a-col>
              <a-col :sm="24" :md="12" class="pt-10" v-if="false">
                <input-text
                    id="customer.email.value"
                    :label="data.customer.email.title"
                    :data="data.customer.email.value"
                    :error="submitted ? data.customer.email.error : null"
                />
              </a-col>
            </a-row>
          </div>
          <div class="well">
            <h3 class="pt-20">Uw woning</h3>
            <a-row :gutter="10">
              <a-col :sm="24" :md="20" :lg="16">
                <a-row :gutter="10">
                  <a-col :sm="16" :md="12">
                    <input-text
                        id="customer.address.postal_code.value"
                        :label="data.customer.address.postal_code.title"
                        :data="data.customer.address.postal_code.value"
                        :error="submitted ? data.customer.address.postal_code.error : null"
                        @input="checkAddress"
                    />
                  </a-col>
                  <a-col :sm="8" :md="6">
                    <input-text
                        id="customer.address.number.value"
                        :label="data.customer.address.number.title"
                        :data="data.customer.address.number.value"
                        :error="submitted ? data.customer.address.number.error : null"
                        @input="checkAddress"
                    />
                  </a-col>
                  <a-col :sm="8" :md="6">
                    <input-text
                        id="customer.address.addition.value"
                        :label="data.customer.address.addition.title"
                        :data="data.customer.address.addition.value"
                        :error="submitted ? data.customer.address.addition.error : null"
                        @input="checkAddress"
                    />
                  </a-col>
                </a-row>
                <a-row :gutter="10" class="pt-10">
                  <a-col :sm="24" :md="10">
                    <input-text
                        id="customer.address.street.value"
                        :label="data.customer.address.street.title"
                        :data="data.customer.address.street.value"
                        :error="submitted ? data.customer.address.street.error : null"
                    />
                  </a-col>
                  <a-col :sm="24" :md="14">
                    <input-text
                        id="customer.address.place.value"
                        :label="data.customer.address.place.title"
                        :data="data.customer.address.place.value"
                        :error="submitted ? data.customer.address.place.error : null"
                    />
                  </a-col>
                </a-row>
              </a-col>
              <a-col :sm="24" :md="4" :lg="8">
                <a-row :gutter="10" v-if="!isHypotheekwacht || (isHypotheekwacht && !isPartner)">
                  <a-col :sm="24" :md="24" :lg="22">
                    <input-money
                        id="customer.address.woz_waarde.value"
                        :label="data.customer.address.woz_waarde.title"
                        :data="data.customer.address.woz_waarde.value"
                        :error="submitted && data.customer.address.woz_waarde.error !== null"
                    />
                  </a-col>
                </a-row>
                <a-row :gutter="10">
                  <a-col :sm="24" :md="24" :lg="22" class="pt-10">
                    <input-money
                        id="customer.address.marktwaarde_woning.value"
                        :label="data.customer.address.marktwaarde_woning.title"
                        :data="data.customer.address.marktwaarde_woning.value"
                        :error="submitted && data.customer.address.marktwaarde_woning.error !== null"
                    />
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
          <div class="well">
            <h3 class="pt-20">Werkgevers</h3>
            <a-row :gutter="10" class="pt-10">
              <a-col :sm="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      v-if="!isHypotheekwacht"
                      id="customer.has_employers.value"
                      :label="data.customer.has_employers.title"
                      :data="data.customer.has_employers.value"
                      :options="data.customer.has_employers.options"
                      :error="submitted && data.customer.has_employers.error !== null"
                  />
                  <input-radio
                      v-if="data.customer.has_employers.value === 0"
                      id="customer.is_pensioned.value"
                      :label="data.customer.is_pensioned.title"
                      :data="data.customer.is_pensioned.value"
                      :options="data.customer.is_pensioned.options"
                      :error="submitted && data.customer.is_pensioned.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
            <a-row :gutter="10" v-if="data.customer.has_employers.value === 1">
              <a-col span="24" class="pt-20" v-if="data.customer.has_employers.value === 1">
                <div v-for="(employer, e) in data.customer.employers" :key="e">
                  <a-row :gutter="10" class="pt-20">
                    <a-col :sm="16">
                      <h4>{{ employer.name.value ? employer.name.value : 'Voer een werkgever in...' }}</h4>
                    </a-col>
                    <a-col :sm="8">
                      <a-button class="btn-small danger right-align" @click="removeEmployer(e)">
                        <i class="mdi mdi-account-remove"/> Verwijderen
                      </a-button>
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="16" :md="12" :lg="12">
                      <input-text
                          :id="`customer.employers.${e}.name.value`"
                          :label="employer.name.title"
                          :data="employer.name.value"
                          :error="submitted ? employer.name.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="16" :md="10" :lg="7">
                      <input-money
                          :id="`customer.employers.${e}.salary.value`"
                          :label="employer.salary.title"
                          :data="employer.salary.value"
                          :error="submitted ? employer.salary.error : null"
                          :tooltip="!isHypotheekwacht ? 'Let op, dit is het bedrag exclusief vakantiegeld' : null"
                      />
                    </a-col>
                    <a-col :sm="16" :md="10" :lg="7" v-if="!isHypotheekwacht">
                      <input-number
                          :id="`customer.employers.${e}.working_hours.value`"
                          :label="employer.working_hours.title"
                          :data="employer.working_hours.value"
                          :error="submitted ? employer.working_hours.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10" v-if="!isHypotheekwacht">
                    <a-col :sm="24" :md="10" :lg="7">
                      <input-date
                          :id="`customer.employers.${e}.date_of_service.value`"
                          :label="employer.date_of_service.title"
                          :data="employer.date_of_service.value"
                          :error="submitted ? employer.date_of_service.error : null"
                      />
                    </a-col>
                    <a-col :sm="24" :md="10" :lg="7">
                      <input-text
                          :id="`customer.employers.${e}.function.value`"
                          :label="employer.function.title"
                          :data="employer.function.value"
                          :error="submitted ? employer.function.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-20" v-if="!isHypotheekwacht">
                    <a-col :sm="24" :md="24" :lg="24">
                      <table class="ant-table">
                        <tbody class="ant-table-body">
                        <input-radio
                            :id="`customer.employers.${e}.permanent_service.value`"
                            :label="employer.permanent_service.title"
                            :data="employer.permanent_service.value"
                            :options="employer.permanent_service.options"
                            :error="submitted ? employer.permanent_service.error : null"
                        />
                        <input-radio
                            :id="`customer.employers.${e}.has_car.value`"
                            :label="employer.has_car.title"
                            :data="employer.has_car.value"
                            :options="employer.has_car.options"
                            :error="submitted ? employer.has_car.error : null"
                        />
                        </tbody>
                      </table>
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10" v-if="employer.has_car.value === 1">
                    <a-col :sm="24" :md="8" :lg="12"></a-col>
                    <a-col :sm="24" :md="8" :lg="6">
                      <input-money
                          :id="`customer.employers.${e}.car_addition.value`"
                          :label="employer.car_addition.title"
                          :data="employer.car_addition.value"
                          :error="submitted ? employer.car_addition.error : null"
                      />
                    </a-col>
                    <a-col :sm="24" :md="8" :lg="6">
                      <input-money
                          :id="`customer.employers.${e}.car_contribution.value`"
                          :label="employer.car_contribution.title"
                          :data="employer.car_contribution.value"
                          :error="submitted ? employer.car_contribution.error : null"
                      />
                    </a-col>
                  </a-row>
                </div>
              </a-col>
              <a-col span="24" class="pt-20">
                <a-button v-if="data.customer.employers.length === 0" class="inverted" @click="addEmployer">
                  <i class="mdi mdi-account-plus"/>&nbsp;&nbsp;Werkgever toevoegen
                </a-button>
                <a-button v-if="data.customer.employers.length > 0" class="btn-small inverted" @click="addEmployer">
                  <i class="mdi mdi-account-plus"/>&nbsp;&nbsp;Nog een werkgever toevoegen
                </a-button>
              </a-col>
            </a-row>
          </div>
          <div class="well" v-if="!isHypotheekwacht">
            <h3>Kamer van Koophandel</h3>
            <a-row :gutter="10" class="pt-10">
              <a-col :sm="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="customer.has_companies.value"
                      :label="data.customer.has_companies.title"
                      :data="data.customer.has_companies.value"
                      :options="data.customer.has_companies.options"
                      :error="submitted && data.customer.has_companies.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
            <a-row :gutter="10" v-if="data.customer.has_companies.value === 1">
              <a-col span="24" class="pt-20" v-if="data.customer.has_companies.value === 1">
                <div v-for="(company, c) in data.customer.companies" :key="c">
                  <a-row :gutter="10" class="pt-20">
                    <a-col :sm="16">
                      <h3>{{ company.name.value ? company.name.value : 'Voer een bedrijfsnaam in...' }}</h3>
                    </a-col>
                    <a-col :sm="8">
                      <a-button class="btn-small danger right-align" @click="removeCompany(c)">
                        <i class="mdi mdi-account-remove"/> Verwijderen
                      </a-button>
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="16" :md="12" :lg="12">
                      <input-text
                          :id="`customer.companies.${c}.name.value`"
                          :label="company.name.title"
                          :data="company.name.value"
                          :error="submitted ? company.name.error : null"
                      />
                    </a-col>
                    <a-col :sm="16" :md="12" :lg="9">
                      <input-text
                          :id="`customer.companies.${c}.registration_number.value`"
                          :label="company.registration_number.title"
                          :data="company.registration_number.value"
                          :error="submitted ? company.registration_number.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="16" :md="12" :lg="8">
                      <input-select
                          :id="`customer.companies.${c}.legal_type.value`"
                          :label="company.legal_type.title"
                          :data="company.legal_type.value"
                          :options="company.legal_type.options"
                          :error="submitted ? company.legal_type.error : null"
                      />
                    </a-col>
                    <a-col :sm="16" :md="12" :lg="4">
                      <input-select
                          :id="`customer.companies.${c}.is_major_shareholder.value`"
                          :label="company.is_major_shareholder.title"
                          :data="company.is_major_shareholder.value"
                          :options="company.is_major_shareholder.options"
                          :error="submitted ? company.is_major_shareholder.error : null"
                      />
                    </a-col>
                    <a-col :sm="16" :md="12" :lg="4">
                      <input-select
                          :id="`customer.companies.${c}.has_personnel.value`"
                          :label="company.has_personnel.title"
                          :data="company.has_personnel.value"
                          :options="company.has_personnel.options"
                          :error="submitted ? company.has_personnel.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="12" :md="12" :lg="12">
                      <input-date
                          :id="`customer.companies.${c}.start_date.value`"
                          :label="company.start_date.title"
                          :data="company.start_date.value"
                          :error="submitted ? company.start_date.error : null"
                      />
                    </a-col>
                  </a-row>
                </div>
              </a-col>
              <a-col span="24" class="pt-20">
                <a-button v-if="data.customer.companies.length === 0" class="inverted" @click="addCompany">
                  <i class="mdi mdi-account-plus"/>&nbsp;&nbsp;Bedrijf toevoegen
                </a-button>
                <a-button v-if="data.customer.companies.length > 0" class="btn-small inverted" @click="addCompany">
                  <i class="mdi mdi-account-plus"/>&nbsp;&nbsp;Nog een bedrijf toevoegen
                </a-button>
              </a-col>
            </a-row>
          </div>
          <div class="well" v-if="showPartner(data)">
            <h3 class="pt-20">Partner</h3>
            <a-row :gutter="10" class="pt-10">
              <a-col :sm="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="customer.has_partner.value"
                      :label="data.customer.has_partner.title"
                      :data="data.customer.has_partner.value"
                      :options="data.customer.has_partner.options"
                      :error="submitted && data.customer.has_partner.error !== null"
                  />
                  <input-radio
                      v-if="data.customer.has_partner.value === 1 && !isHypotheekwacht && data.invitationType !== 'financial_advice' && data.invitationType !== 'partner_financial_advice'"
                      id="customer.has_mortgage_with_partner.value"
                      :label="data.customer.has_mortgage_with_partner.title"
                      :data="data.customer.has_mortgage_with_partner.value"
                      :options="data.customer.has_mortgage_with_partner.options"
                      :error="submitted && data.customer.has_mortgage_with_partner.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
            <div v-if="(isHypotheekwacht && data.customer.has_partner.value === 1) || (!isHypotheekwacht && data.customer.has_partner.value === 1 && data.customer.has_mortgage_with_partner.value === 1)" class="pt-20 pb-30">
              <h4 class="pt-20">Gegevens partner <small v-if="isHypotheekwacht">({{ data.customer.partner.first_name.value }} {{ data.customer.partner.insertion.value }} {{ data.customer.partner.last_name.value }})</small></h4>
              <a-row>
                <a-col :sm="14" v-if="!isHypotheekwacht">
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="8" :md="8">
                      <input-select
                          id="customer.partner.salutation.value"
                          :label="data.customer.partner.salutation.title"
                          :data="data.customer.partner.salutation.value"
                          :options="data.customer.partner.salutation.options"
                          :error="submitted ? data.customer.partner.salutation.error : null"
                      />
                    </a-col>
                    <a-col :sm="16" :md="14">
                      <input-text
                          id="customer.partner.first_name.value"
                          :label="data.customer.partner.first_name.title"
                          :data="data.customer.partner.first_name.value"
                          :error="submitted ? data.customer.partner.first_name.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="16" :md="14">
                      <input-text
                          id="customer.partner.last_name.value"
                          :label="data.customer.partner.last_name.title"
                          :data="data.customer.partner.last_name.value"
                          :error="submitted ? data.customer.partner.last_name.error : null"
                      />
                    </a-col>
                    <a-col :sm="8" :md="8">
                      <input-text
                          id="customer.partner.insertion.value"
                          :label="data.customer.partner.insertion.title"
                          :data="data.customer.partner.insertion.value"
                          :error="submitted ? data.customer.partner.insertion.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="24" :md="14" :lg="14">
                      <input-text
                          id="customer.partner.email.value"
                          :label="data.customer.partner.email.title"
                          :data="data.customer.partner.email.value"
                          :error="submitted ? data.customer.partner.email.error : null"
                      />
                    </a-col>
                    <a-col :sm="24" :md="24" :lg="24" class="pt-10" v-if="partnerInvited(data)">
                      <a-alert message="Let op!"
                               description="Uw partner ontvangt een separate uitnodiging op het e-mailadres wat u hierboven heeft ingevuld. Uw partner dient op deze link te klikken. Hierdoor start er een sessie in een zogenaamde partneromgeving. Uw partner kan dus GEEN gebruik maken van het inventarisatieportaal waarin u nu bezig bent."
                               type="error"
                               show-icon
                      />
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :sm="10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="20" class="pt-10">
                      <input-date
                          id="customer.partner.date_of_birth.value"
                          :label="data.customer.partner.date_of_birth.title"
                          :data="data.customer.partner.date_of_birth.value"
                          :error="submitted ? data.customer.partner.date_of_birth.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" v-if="!isHypotheekwacht">
                    <a-col :sm="24" :md="14" class="pt-10">
                      <input-select
                          id="customer.partner.gender.value"
                          :label="data.customer.partner.gender.title"
                          :data="data.customer.partner.gender.value"
                          :options="data.customer.partner.gender.options"
                          :error="submitted ? data.customer.partner.gender.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" v-if="!isHypotheekwacht">
                    <a-col :sm="24" :md="24" :lg="22" class="pt-10">
                      <input-money
                          id="customer.partner.salaris_bruto_per_maand.value"
                          :label="data.customer.partner.salaris_bruto_per_maand.title"
                          :data="data.customer.partner.salaris_bruto_per_maand.value"
                          :error="submitted ? data.customer.partner.salaris_bruto_per_maand.error : null"
                          :tooltip="!isHypotheekwacht ? 'Let op, dit is het bedrag exclusief vakantiegeld' : null"
                      />
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :sm="24" :md="20" v-if="!isHypotheekwacht" class="mt-10">
                  <input-check-box
                      id="customer.partner.different_address.value"
                      :label="data.customer.partner.different_address.title"
                      :data="data.customer.partner.different_address.value"
                      :error="submitted && data.customer.partner.different_address.error !== null"
                  />
                </a-col>
              </a-row>
              <a-row v-if="data.customer.partner.different_address.value === 1 && !isHypotheekwacht" :gutter="10"
                     class="pt-10">
                <a-col :sm="24" :md="18" :lg="14">
                  <h4 class="pt-20">Adres partner</h4>
                  <a-row :gutter="10">
                    <a-col :sm="16" :md="12">
                      <input-text
                          id="customer.partner.address.street.value"
                          :label="data.customer.partner.address.street.title"
                          :data="data.customer.partner.address.street.value"
                          :error="submitted ? data.customer.partner.address.street.error : null"
                      />
                    </a-col>
                    <a-col :sm="8" :md="6">
                      <input-text
                          id="customer.partner.address.number.value"
                          :label="data.customer.partner.address.number.title"
                          :data="data.customer.partner.address.number.value"
                          :error="submitted ? data.customer.partner.address.number.error : null"
                      />
                    </a-col>
                    <a-col :sm="8" :md="6">
                      <input-text
                          id="customer.partner.address.addition.value"
                          :label="data.customer.partner.address.addition.title"
                          :data="data.customer.partner.address.addition.value"
                          :error="submitted ? data.customer.partner.address.addition.error : null"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="24" :md="10">
                      <input-text
                          id="customer.partner.address.postal_code.value"
                          :label="data.customer.partner.address.postal_code.title"
                          :data="data.customer.partner.address.postal_code.value"
                          :error="submitted ? data.customer.partner.address.postal_code.error : null"
                      />
                    </a-col>
                    <a-col :sm="24" :md="14">
                      <input-text
                          id="customer.partner.address.place.value"
                          :label="data.customer.partner.address.place.title"
                          :data="data.customer.partner.address.place.value"
                          :error="submitted ? data.customer.partner.address.place.error : null"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="well" v-if="!isHypotheekwacht">
            <h3 class="pt-20">Kinderen</h3>
            <a-row :gutter="10" class="pt-10">
              <a-col :sm="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="customer.has_children.value"
                      :label="data.customer.has_children.title"
                      :data="data.customer.has_children.value"
                      :options="data.customer.has_children.options"
                      :error="submitted && data.customer.has_children.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
            <a-row :gutter="10" v-if="data.customer.has_children.value === 1">
              <a-col span="24">
                <div v-for="(child, c) in data.customer.children" :key="c" class="mt-20">
                  <a-row :gutter="10" class="pt-10">
                    <a-col :sm="16">
                      <h4>{{
                          child.first_name.value ? child.first_name.value : 'Voer een naam in...'
                        }}</h4>
                    </a-col>
                    <a-col :sm="8">
                      <a-button class="btn-small danger right-align" @click="removeChild(c)">
                        <i class="mdi mdi-account-remove"/> Verwijderen
                      </a-button>
                    </a-col>
                    <a-col :sm="24">
                      <a-row :gutter="10">
                        <a-col :sm="16" :md="9" :lg="7">
                          <input-text
                              :id="`customer.children.${c}.first_name.value`"
                              :label="child.first_name.title"
                              :data="child.first_name.value"
                              :error="submitted ? child.first_name.error : null"
                          />
                        </a-col>
                        <a-col :sm="8" :md="6" :lg="5">
                          <input-text
                              :id="`customer.children.${c}.insertion.value`"
                              :label="child.insertion.title"
                              :data="child.insertion.value"
                              :error="submitted ? child.insertion.error : null"
                          />
                        </a-col>
                        <a-col :sm="16" :md="9" :lg="7">
                          <input-text
                              :id="`customer.children.${c}.last_name.value`"
                              :label="child.last_name.title"
                              :data="child.last_name.value"
                              :error="submitted ? child.last_name.error : null"
                          />
                        </a-col>
                      </a-row>
                      <a-row :gutter="10" class="pt-10">
                        <a-col :sm="24">
                          <a-row :gutter="10">
                            <a-col :sm="12" :md="12" :lg="12">
                              <input-date
                                  :id="`customer.children.${c}.date_of_birth.value`"
                                  :label="child.date_of_birth.title"
                                  :data="child.date_of_birth.value"
                                  :error="submitted ? child.date_of_birth.error : null"
                              />
                            </a-col>
                            <a-col :sm="12" :md="12" :lg="7">
                              <input-select
                                  :id="`customer.children.${c}.gender.value`"
                                  :label="child.gender.title"
                                  :data="child.gender.value"
                                  :options="child.gender.options"
                                  :error="submitted ? child.gender.error : null"
                              />
                            </a-col>
                          </a-row>
                        </a-col>
                        <a-col :sm="24" class="mt-10">
                          <table class="ant-table">
                            <tbody class="ant-table-body">
                            <input-radio
                                :id="`customer.children.${c}.lives_at_home.value`"
                                :label="child.lives_at_home.title"
                                :data="child.lives_at_home.value"
                                :options="child.lives_at_home.options"
                                :error="submitted && child.lives_at_home.error !== null"
                            />
                            <input-radio
                                :id="`customer.children.${c}.studies.value`"
                                :label="child.studies.title"
                                :data="child.studies.value"
                                :options="child.studies.options"
                                :error="submitted && child.studies.error !== null"
                            />
                            </tbody>
                          </table>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </div>
              </a-col>
              <a-col span="24" class="pt-20">
                <a-button v-if="data.customer.children.length === 0" @click="addChild" class="inverted">
                  <i class="mdi mdi-account-plus"/>&nbsp;&nbsp;Kind toevoegen
                </a-button>
                <a-button v-if="data.customer.children.length > 0" @click="addChild" class="btn-small inverted">
                  <i class="mdi mdi-account-plus"/>&nbsp;&nbsp;Nog een kind toevoegen
                </a-button>
              </a-col>
            </a-row>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="personal_details" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import { isMobile, nextRoute, PERSONAL_DETAILS, previousRoute, router } from '@/helpers'
import SideNav from '../../../components/SideNav/SideNav'
import InputRadio from '../../../components/Input/InputRadio'
import InputText from '../../../components/Input/InputText'
import InputDate from '../../../components/Input/InputDate'
import InputNumber from '../../../components/Input/InputNumber'
import InputCheckBox from '../../../components/Input/InputCheckBox'
import InputSelect from '../../../components/Input/InputSelect'
import InputMoney from '../../../components/Input/InputMoney'
import { alertService, propertyService } from '@/services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import * as Sentry from '@sentry/vue'

export default {
  name: 'step-personal-details',
  components: {
    InputCheckBox,
    InputSelect,
    SideNav,
    InputMoney,
    InputNumber,
    InputRadio,
    InputText,
    InputDate,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapState('survey', ['isHypotheekwacht']),
    data () {
      return {
        ...this.$store.state.survey,
        invitationType: this.$store.state.survey.customer.invitation.type.value
      }
    },
    isPartner () {
      return this.data?.customer?.is_partner?.value
    }
  },
  methods: {
    ...mapActions('survey', ['validate', 'addChild', 'removeChild', 'addEmployer', 'removeEmployer', 'addCompany', 'removeCompany', 'postCustomer', 'submit']),
    showPartner () {
      return true
    },
    partnerInvited (data) {
      return data.customer.invitation.partner_invited.value === 1
    },
    getHWSalutations () {
      return [
        { value: 'De heer', label: 'De heer', show: true },
        { value: 'Mevrouw', label: 'Mevrouw', show: true }
      ]
    },
    checkAddress: _.debounce(function () {
      const postalCode = this.data.customer.address.postal_code.value
      const number = this.data.customer.address.number.value
      const addition = this.data.customer.address.addition.value
      let numberLetter = null
      let numberAddition = null

      if (addition !== null && typeof addition !== 'undefined' && addition.length > 0) {
        const letterAndAdditionMatch = addition.match(/^([a-zA-Z]+)[- ]*([0-9A-Za-z]+)$/)
        const letterMatch = addition.match(/^([a-zA-Z]+)$/)
        const additionMatch = addition.match(/^(\w*)$/)

        if (letterAndAdditionMatch !== null) { // Check if number letter and addition are present
          numberLetter = letterAndAdditionMatch[1]
          numberAddition = letterAndAdditionMatch[2]
        } else if (letterMatch !== null) { // Check if number letter is present
          numberLetter = letterMatch[1]
        } else if (additionMatch !== null) { // Check if addition is present
          numberAddition = additionMatch[1]
        }
      }

      propertyService.findByPostalCodeAndNumberAndAddition(postalCode, number, numberLetter, numberAddition)
        .then(property => {
          this.submit({
            name: 'customer.address.street.value',
            value: property.street
          })
          this.submit({
            name: 'customer.address.place.value',
            value: property.city
          })
        })
        .catch(error => {
          console.error(error)
          Sentry.captureException(error)
        })
    }, 500),
    onPrevious (data) {
      router.push(previousRoute(PERSONAL_DETAILS, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      data.isHypotheekWacht = this.isHypotheekwacht
      this.submitted = true
      this.validate({ key: PERSONAL_DETAILS, data })
      if (data.status.personal_details) {
        router.push(nextRoute(PERSONAL_DETAILS, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
