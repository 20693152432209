<template>
  <div class="content">
    <div class="container complete">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <a-row>
            <a-col :sm="24" :md="4" :lg="4">
              <img src="../../../assets/groen.png" style="max-height: 80px"/>
            </a-col>
            <a-col :sm="24" :md="20" :lg="20">
              <h1>Bedankt, uw inventarisatie is verzonden!</h1>
              <p>
                De inventarisatie is compleet en we hebben uw gegevens ontvangen.
              </p>
              <p v-if="isRedirecting">
                <img
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                U wordt nu automatisch doorverwezen.
              </p>
              <p v-if="!isRedirecting">
                U kunt de pagina nu afsluiten. Uw persoonlijke gegevens en de extensie zijn verwijderd.
              </p>
              <div class="manual" v-if="partnerInvited(data)" :style="viewStyle(data)">
                <div class="desc">
                  <h3 :style="fontStyle(data)">{{ getTitle(data) }}</h3>
                  <p :style="fontStyle(data)">{{ getText1(data) }}</p>
                  <p :style="fontStyle(data)">{{ getText2(data) }}</p>
                  <p :style="fontStyle(data)">{{ getText3(data) }}</p>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="success" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile, isPartnerInvited } from '../../../helpers'
import SideNav from '../../../components/SideNav/SideNav'
import { mapActions, mapState } from 'vuex'
import { alertService } from '../../../services'

export default {
  name: 'SuccessComponent',
  components: { SideNav },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapState('auth', ['redirect_success']),
    data () {
      return this.$store.state.survey
    },
    isRedirecting () {
      return this.redirect_success !== null && this.redirect_success.length > 0
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    viewStyle (data) {
      return {
        color: data.style.warning.color,
        backgroundColor: data.style.warning.backgroundColor
      }
    },
    fontStyle (data) {
      return { color: data.style.warning.color }
    },
    partnerInvited (data) {
      return data.customer.invitation.partner_invited.value === 1 || data.customer.invitation.partner_invited.value === '1'
    },
    getTitle (data) {
      return 'De inventarisatie is geslaagd'
    },
    getText1 (data) {
      return 'De inventarisatie is compleet en we hebben uw gegevens ontvangen.'
    },
    getText2 (data) {
      const invitation = this.$store.state.survey.customer.invitation
      return isPartnerInvited(invitation)
        ? 'Uw partner heeft een separate uitnodiging via de e-mail ontvangen. ' +
          'Uw partner dient op de link in deze e-mail te klikken'
        : ''
    },
    getText3 (data) {
      const invitation = this.$store.state.survey.customer.invitation
      return isPartnerInvited(invitation)
        ? 'Hierdoor start een sessie in de zogenaamde partneromgeving. ' +
          'Uw partner kan dus GEEN gebruik maken van uw inventarisatieportaal.'
        : ''
    }
  },
  mounted: function () {
    const redirectSuccess = `${this.$store.state.auth.redirect_success}`
    const methods = this
    setTimeout(function () {
      alertService.onSuccess('U bent nu uitgelogd')
      console.log('success.mounted.logout', redirectSuccess)
      methods.logout({ redirect: redirectSuccess })
    }, 5000)
  }
}
</script>
