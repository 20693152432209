import Toast from 'v-toast'
import * as Sentry from '@sentry/vue'

export const alertService = {
  onSuccess,
  onError
}

function onSuccess (message) {
  Toast.info({
    message,
    duration: 3000
  })

  Sentry.addBreadcrumb({
    type: 'info',
    level: 'info',
    message: 'AlertService success:',
    data: {
      message
    }
  })
}

function onError (message) {
  Toast.error({
    message,
    duration: 3000
  })
  Sentry.captureException(new Error('AlertService: ' + message))
}
