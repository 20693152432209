<template>
  <div class="content">
    <div class="container intro">
      <div class="flex">
        <div class="main well" v-if="this.isMobile">
          <a-row :gutter="50" class="pt-10">
            <a-col :sm="24">
              <h1>Aan de slag</h1>
              <p>Deze website is niet beschikbaar op mobiel.</p>
            </a-col>
          </a-row>
        </div>
        <div class="main_loading" v-if="getSurvey().status.loading">
          <a-spin size="large"/>
        </div>
        <div class="main" v-if="!this.isMobile && this.isSupportedBrowser && !getSurvey().status.loading">
          <a-row :gutter="50">
            <a-col :sm="24">
              <h1>{{ title }}</h1>
              <p>{{ text }}</p>
            </a-col>
          </a-row>
          <a-row :gutter="50">
            <a-col :sm="24">
              <h2>Hoe gaat u gegevens aanleveren?</h2>
              <p>{{ dataProvideText }}</p>
            </a-col>
            <a-col :sm="12" :md="12" :lg="getViews(getSurvey()).length > 1 ? 12 : 24"
                   v-for="(view,idx) in getViews(getSurvey())" :key="idx"
            >
              <div class="manual" v-if="view.text_1" :style="viewStyle(view)">
                <div :class="getViews(getSurvey()).length > 1 ? 'desc' : ''">
                  <h3 :style="fontStyle(view)">{{ view.title }}</h3>
                  <p :style="fontStyle(view)">Klik op de onderstaande button om te starten.</p>
                  <a-button
                      :class="view.buttonClass"
                      :loading="getSurvey().status.loading"
                      size="large"
                      type="primary"
                      @click="next(view.route)"
                  >
                    {{view.buttonText}}
                  </a-button>
                  <p :style="fontStyle(view)">{{ view.text_1 }}</p>
                  <p :style="fontStyle(view)">{{ view.text_2 }}</p>
                  <p :style="fontStyle(view)">{{ view.text_3 }}</p>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row class="pt-20" :gutter="50">
            <a-col :sm="12" :md="12" :lg="getViews(getSurvey()).length > 1 ? 12 : 24"
                   v-for="(view,idx) in getViews(getSurvey())" :key="idx"
            >
              <div v-if="!view.text_1" :class="getViews(getSurvey()).length > 1 ? 'cta' : ''">
                <a-button
                    :class="view.buttonClass"
                    :loading="getSurvey().status.loading"
                    size="large"
                    type="primary"
                    @click="next(view.route)"
                >
                  {{view.buttonText}}
                </a-button>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="intro" :loading="getSurvey().status.loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDataProvideText,
  getIntro,
  getInvitationName,
  getInvitationText,
  isMobile,
  isSupportedBrowser,
  LOGOUT,
  router
} from '../../../helpers'
import { mapActions, mapState } from 'vuex'
import SideNav from '../../../components/SideNav/SideNav'

export default {
  name: 'IntroComponent',
  components: { SideNav },
  computed: {
    ...mapState('auth', ['invitation_token']),
    ...mapState('survey', ['isHypotheekwacht'])
  },
  data () {
    const invType = this.$store.state.survey.customer.invitation.type.value
    return {
      invitationType: invType,
      title: getInvitationName(invType),
      text: getInvitationText(invType),
      dataProvideText: getDataProvideText(invType),
      isSupportedBrowser: isSupportedBrowser(),
      isMobile: isMobile()
    }
  },
  watch: {
    '$store.state.survey.customer.invitation.type.value': function () {
      const invType = this.$store.state.survey.customer.invitation.type.value
      this.invitationType = invType
      this.title = getInvitationName(invType)
      this.text = getInvitationText(invType)
      this.dataProvideText = getDataProvideText(invType)
    }
  },
  mounted: function () {
    this.getTenantSetting('iwize_integration')
    this.getCustomer(this.invitation_token)
    this.getHDNCompanies()
    this.getHDNCompanyLabels()
  },
  methods: {
    ...mapActions('survey', ['getCustomer', 'getHDNCompanies', 'getHDNCompanyLabels']),
    ...mapActions('auth', ['logout', 'getTenantSetting']),
    onLoad (e) {
      this.getTenantSetting('iwize_integration')
      this.getCustomer()
      this.getHDNCompanies()
      this.getHDNCompanyLabels()
    },
    getViews (data) {
      return getIntro(data.style, data.customer.has_partner.value, data.customer.invitation)
    },
    getSurvey () {
      return this.$store.state.survey
    },
    next (route) {
      if (route === LOGOUT) {
        this.logout({ redirect: '/login' })
      } else {
        router.push(route)
      }
    },
    viewStyle (view) {
      return {
        color: view.color,
        backgroundColor: view.backgroundColor
      }
    },
    fontStyle (view) {
      return {
        color: view.color
      }
    }
  }
}
</script>
